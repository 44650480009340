<template>
  <div class="rating-element">
    <div class="msg">
      Wie fanden Sie die Unterhaltung? Wollen Sie uns Ihre Meinung mitteilen?
    </div>
    <select v-model="ratingValue">
      <option>1</option>
      <option>2</option>
      <option>3</option>
      <option>4</option>
      <option>5</option>
      <option>6</option>
      <option>7</option>
      <option>8</option>
      <option>9</option>
      <option>10</option>
    </select>
    <label for="comment">Kommentar</label>
    <textarea id="comment" v-model="comment"></textarea>
    <button @click="$emit('rating', {'comment':comment, 'evaluation': ratingValue})">Senden</button>
  </div>
</template>

<script>
export default {
  name: 'RatingElement',
  data() {
    return {comment: '', ratingValue: 1}
  }
}
</script>

<style scoped lang="scss">
.rating-element {
  padding: 30px 20px;

  label {
    text-align: left;
    display: block;
  }

  textarea {
    width: 100%;
    height: 100px;
  }

  button {
    display: block;
  }
}
</style>
