<template>
  <div :class="msgClass">
    <div class="message">
      <v-icon class="form-button" v-if="request.form != '' && request.form != null"
              @click="$emit('form', request.form)">mdi-format-list-bulleted-square
      </v-icon>
      <div class="message-content">
        <img v-if="agent" class="avatar" :src="request.agentPath">
        <div class="message-text">
          <span class="timestamp">{{ time.toLocaleTimeString('de-de') }}</span>
          <span ref="msgElement" v-html="msg" @click="clickMsg" v-if="msg != null"></span>
          <div class="clickable" @click="download()" v-if="request.type == 'link'">Download: {{ request.name }}</div>
          <video ref="video" controls v-if="request.type == 'video'">
            <source :src="request.mediaPath">
          </video>
          <audio ref="audio" controls v-if="request.type == 'audio'">
            <source :src="request.mediaPath">
          </audio>
          <img class="inline-image" :src="request.mediaPath" v-if="request.type == 'image'">
        </div>
      </div>
      <div class="options" v-if="options.length != 0">
        <div class="clickable" @click="$emit('say', option)" v-for="(option, i) in options" :key="i">{{
            option
          }}
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import FileSaver from 'file-saver';
import Plyr from 'plyr';
import {ChatApi} from '@/api/ChatApi';

export default {
  name: 'ChatEntry',
  props: ['request', 'time', 'agent'],
  data() {
    return {
      options: [],
      msg: '',
      image: '',
      isClient: true,
      eventFired: false,
      trackingFired: false,
    }
  },
  computed: {
    msgClass: {
      get() {
        return (this.isClient ? 'client-message' : 'server-message') + (this.agent ? ' agent' : '');
      }
    },
  },
  methods: {
    download() {
      FileSaver.saveAs(this.request.blob, this.request.name)
    },
    clickMsg(event) {
      if (event.target.nodeName == 'SB-OPTION') {
        if (this.eventFired) {
          return
        }
        this.$emit('say', event.target.dataset['option'])
        // this.eventFired = true
      }
    },
    changeListener(ev) {
      if (this.eventFired) {
        return
      }
      this.$emit('say', ev.target.selectedOptions[0].innerText)
      ev.target.setAttribute('disabled', true)
      ev.target.removeListener('change', this.changeListener)
    },
    track() {
      if (!this.trackingFired) {
        ChatApi.track(this.request.type, this.request.mediaPath)
      }
      this.trackingFired = true
    }
  },
  mounted() {
    // this.$refs.msgElement.addEventListener('DOMChanged', e => console.log(e))
    this.isClient = this.request.agent == null
    switch (this.request.type) {
      case 'audio':
      case 'video':
        var player
        if (this.request.type == 'video') {
          player = new Plyr(this.$refs.video);
        } else {
          player = new Plyr(this.$refs.audio);
        }
        player.on('playing', () => {
          this.track()
        })
        break;
      case 'link':
        // this.isClient = false
        // this.msg = `<a href="${this.request.msg}" target="_blank">${this.request.name}</a>`
        break;
      case 'image':
        this.track();
        // eslint-disable-next-line
      default: {
        if (this.request.agent == null) {
          this.msg = this.request.msg
          // this.isClient = true
        } else {
          // this.isClient = false
          var s = this.request.msg

          if (s.indexOf('<option>') != -1) {
            var result = ''
            this.options = []
            while (s.indexOf('<option>') != -1) {
              result += s.substring(0, s.indexOf('<option>'))
              s = s.substring(s.indexOf('<option>') + '<option>'.length)
              var opt = s.substring(0, s.indexOf('</option>'))
              //this.options.push(opt)
              s = s.substring(opt.length + '</option>'.length)
              result += `<sb-option data-option="${opt}">${opt}</sb-option>`
            }
            result += s

            this.msg = result
          } else {
            this.msg = s
          }
        }
      }
    }
  },
  updated: function () {
    this.$nextTick(function () {
      this.$el.querySelectorAll('select').forEach(el => {
        el.addEventListener('change', this.changeListener)
      })
    })
  }
}
</script>

<style lang="scss">

.client-message,
.server-message {
  display: flex;
  margin-bottom: 5px;

  .avatar {
    width: 50px;
    height: 50px;
    margin-right: 12px;
    border-radius: 50%;
  }


  .message {
    flex: 1;
    display: flex;
    position: relative;
    margin-bottom: 5px;
    align-items: center;
    //flex-wrap: wrap;
    .form-button {
      margin-right: 8px;
      cursor: pointer;

      background: var(--extra-dark-chat-color);
      color: white;
      border-radius: 50%;
      padding: 6px;
    }

    .inline-image {
      max-width: 100%;
      height: auto;
    }

    .message-content {
      margin: 0 0 30px;
      display: inline-block;
      position: relative;
      font-size: 1em;
      align-self: flex-start;
      word-break: break-word;
      white-space: normal;
      color: rgb(34, 34, 34);
      line-height: 1.3;
      background: rgb(255, 255, 255);
      border-radius: 20px;
      padding: 10px 15px;
      display: flex;

      .timestamp {
        //right: 7px;
        //bottom: -15px;
        //opacity: 0.35;
        //position: absolute;
        font-size: 0.7em;
        white-space: nowrap;
        display: block;
      }
    }

    .options {
      width: 100%;
      display: flex;
      gap: 4px;
      flex-wrap: wrap;

      > * {
        cursor: pointer;
      }
    }
  }
}


.server-message {
  .message {
    .message-content {
      text-align: left;
      background: var(--light-chat-color);
    }

    .timestamp {
      color: white;
    }
  }
}

.server-message.agent {
  .message {
    .message-content {
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
      padding-left: 10px;
    }
  }
}

.server-message:not(.agent) {
  .message {
    .message-content {
      border-bottom-left-radius: 0;
    }

    .message-content:before {
      bottom: -14px;
      left: 0;
      width: 0;
      border: 15px solid transparent;
      height: 0;
      content: "";
      position: absolute;
      border-top: 0;
      border-right: 0;
      border-left-color: var(--light-chat-color);
    }
  }
}

.client-message {
  .avatar {
    display: none;
  }

  .message {
    flex-flow: row-reverse;

    .message-content {
      color: rgb(34, 34, 34);
      position: relative;
      background: white;
      max-width: 90%;
      border-bottom-right-radius: 0;
      text-align: right;
    }

    .message-content:before {
      bottom: -14px;
      right: 0;
      width: 0;
      border: 15px solid transparent;
      height: 0;
      content: "";
      position: absolute;
      border-top: 0;
      border-left: 0;
      border-right-color: rgb(255, 255, 255);
    }
  }

}

.clickable {
  cursor: pointer;
}

sb-option {
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
}

@media screen and (min-width: 430px) {
  .message {
    .form-button {
      display: none;
    }
  }
}

</style>
