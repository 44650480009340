import {ApiBase} from '@/api/BaseApi';

export class ChatApi extends ApiBase {
    static sendMsg(msg) {
        return this.getRequest('/bot/ask?text=' + encodeURIComponent(msg)).then(r => JSON.parse(r));
    }

    static sendEvent(eventName, params) {
        var s = '?'
        params.forEach(v => s += 'param=' + v + '&' /*formData.append('param', v)*/)
        return this.getRequest('/bot/events/' + eventName + s).then(r => JSON.parse(r));
    }

    static checkInit() {
        return this.getRequest('/bot/init').then(r => JSON.parse(r));
    }

    static getContexts(path) {
        return this.getRequest('/api/rest/contexts/' + (path || ''))//.then(r => JSON.parse(r))
    }

    static getFAQ(id) {
        return this.getRequest('/bot/faqs/' + (id || ''))//.then(r => JSON.parse(r))
    }

    static suggest(text) {
        return this.getRequest('/bot/suggestions?search=' + (text || ''))//.then(r => JSON.parse(r))
    }

    static validate() {
        return this.getRequest('/bot/validate')
    }

    static track(value, param) {
        return this.getRequest('/stats/' + value + '?param=' + encodeURIComponent(param))
    }

    static sendRating(rating) {
        return this.postRequest('/bot/rate', rating)
    }
}
