import '@mdi/font/css/materialdesignicons.css'
import de from 'vuetify/lib/locale/de';
import Vuetify from 'vuetify/lib/framework';
import { VApp, VBtn, VTextField } from 'vuetify/lib'
import ChatElement from '@/components/ChatElement';

export const vuetify = options => new Vuetify({
    lang: {
        locales: { de },
        current: 'de',
        ...options
    },
});

export const plugin = {
    install(app, options) {
        app.use(Vuetify, {
            components: {
                // Vuetify 2.x requires `VApp` to be the root component, so `VApp`
                // needs to be exported unless you prefer the consumer app provided it
                VApp,
                VBtn,
                VTextField,
                spiribo_chat:ChatElement
            },
            lang: {
                locales: { de },
                current: 'de',
            },
            theme: {
                themes: {
                    light: {
                        primary: '#006E6E',
                    },
                },
            },
            ...options
        })
    }
}
