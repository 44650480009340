<template>
  <div :class="`forumlar-element ${navOpen ? 'open' : 'close'}`">
    <div class="nav-control" @click="navOpen = !navOpen">
      <img class="open" :src="`${imagePrefix}img/chevron-left-white.svg`">
      <img class="close" :src="`${imagePrefix}img/chevron-right-white.svg`">
    </div>
    <div class="formular-container">
      <div class="header" v-if="selectedForm != null">{{ selectedForm.title }}
        <img @click="navOpen=!navOpen" :src="`${imagePrefix}img/chevron-right-white.svg`">
      </div>
      <div class="inline-scroll">
        <v-form ref="form" onSubmit="return false;">
          <div v-if="loading" class="progress-container">
            <v-progress-circular indeterminate></v-progress-circular>
          </div>

          <div v-if="selectedForm != null" class="form-container">
            <div class="description" v-html="selectedForm.description">
            </div>


            <div v-for="prop in form" :key="prop.key" :cy-data="prop.key">
<!--              <p class="description" v-html="prop.description" v-if="prop.description"></p>-->
              <v-menu v-model="menu[prop.key]" :close-on-content-click="false" transition="scale-transition" offset-y
                      min-width="auto" v-if="prop.type == 'date' || prop.type == 'string' && prop.format=='date'">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="formData[prop.key]" :label="prop.label" :placeholder="prop.label"
                                color="var(--middle-chat-text-color, white)"
                                prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on">
                  </v-text-field>
                </template>
                <v-date-picker v-model="dates[prop.key]" @change="menu[prop.key] = false"></v-date-picker>
              </v-menu>

              <v-menu v-model="menu[prop.key]" :close-on-content-click="false" transition="scale-transition" offset-y
                      min-width="auto" v-else-if="prop.type == 'time' || prop.type == 'string' && prop.format=='time'">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="formData[prop.key]" :label="prop.label" :placeholder="prop.label"
                                color="var(--middle-chat-text-color, white)"
                                prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on">
                  </v-text-field>
                </template>
                <v-time-picker v-model="dates[prop.key]" @change="menu[prop.key] = false"></v-time-picker>
              </v-menu>

              <v-select :items="prop.enum" filled v-model="formData[prop.key]" :rules="prop.required? requiredRule:[]"
                        :label="prop.label" :placeholder="prop.label"
                        @change="updateForm" item-text="label" item-value="value"
                        v-else-if="prop.type == 'select' || prop.type == 'dropdown'"></v-select>

              <v-checkbox v-model="formData[prop.key]" filled :label="prop.label"
                          :rules="prop.required? requiredRule:[]" :placeholder="prop.label" @change="updateForm"
                          v-else-if="prop.type == 'boolean'"></v-checkbox>

              <v-textarea v-model="formData[prop.key]" filled :label="prop.label" dense
                          :rules="prop.required? requiredRule:[]" :placeholder="prop.label" @change="updateForm"
                          v-else-if="prop.type == 'text' || prop.type == 'textarea'"></v-textarea>

              <v-text-field v-model="formData[prop.key]" filled :label="prop.label" dense type="number"
                            :rules="prop.required? requiredRule:[]" :placeholder="prop.label" @change="updateForm"
                            v-else-if="prop.type == 'number'"></v-text-field>

              <!--              <v-text-field v-model="formData[prop.key]" filled :label="prop.label" dense type="number"-->
              <!--                            :rules="prop.required? requiredRule:[]" :placeholder="prop.label" @change="updateForm"-->
              <!--                            v-else-if="prop.type == 'datetime'"></v-text-field>-->

              <v-file-input type="file" v-model="formData[prop.key]" :rules="prop.required? requiredRule:[]" filled :label="prop.label" :placeholder="prop.label" @change="updateForm"
                            v-else-if="prop.type == 'file'"></v-file-input>

              <v-text-field v-model="formData[prop.key]" filled :label="prop.label"
                            :rules="prop.required? requiredRule:[]" :placeholder="prop.label" @change="updateForm"
                            v-else></v-text-field>

            </div>
            <span class="subheader" v-if="form.length != 0">Angaben zu Ihrer Person:</span>
            <v-text-field label="Vorname" filled v-model="user.firstName" :rules="requiredRule"
                          cy-data="firstname"></v-text-field>
            <v-text-field label="Nachname" filled v-model="user.lastName" :rules="requiredRule"
                          cy-data="lastname"></v-text-field>
            <v-text-field label="Straße" filled v-model="user.street" :rules="requiredRule"
                          cy-data="street"></v-text-field>
            <v-text-field label="Hausnummer" filled v-model="user.houseNumber" :rules="requiredRule"
                          cy-data="housenr"></v-text-field>
            <v-text-field label="PLZ" type="number" filled v-model="user.postalCode" :rules="requiredRule"
                          cy-data="plz"></v-text-field>
            <v-text-field label="Ort" filled v-model="user.city" :rules="requiredRule" cy-data="city"></v-text-field>
            <v-text-field label="Email-Adresse" type="email" filled v-model="user.email" :rules="emailRule"
                          cy-data="email"></v-text-field>
            <v-text-field label="Telefon" type="phone" filled v-model="user.phoneNumber" :rules="phoneRule"
                          cy-data="phone"></v-text-field>

            <v-checkbox
                label="Ich bin damit einverstanden, auch zu anderen Themen von meinem Vermieter per Email kontaktiert zu werden. Ich kann diese Zustimmung jederzeit widerrufen."
                dense v-model="user.acknowledge" cy-data="ack"></v-checkbox>

            <button @click="validateSend">Absenden</button>
            <span class="legal">Mit dem Absenden des Formulars stimmen Sie den <a :href="datenschutzUrl"
                                                                                  target="_blank">Datenschutzbestimmungen</a> zu.</span>
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormularElement',
  props: ['selectedFormId', 'imagePrefix', 'datenschutzUrl'],
  data() {
    return {
      navOpen: false,
      formularTitle: 'test',
      selectedForm: null,
      loading: false,
      form: [],
      dates: {},
      formData: {},
      user: {},
      menu: {},
      requiredRule: [
        (v) => !!v && v.length != 0 || 'Eingabe ist erforderlich'
      ],
      emailRule: [
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/.test(v) || 'Geben Sie ein gültige Mailadresse ein (z.B. example@gmail.com)',
        (v) => !!v && v.length != 0 || 'Eingabe ist erforderlich'
      ],
      phoneRule: [
        v => !v || /^(\+?[0-9])+$/.test(v) || 'Geben Sie eine gültige Telefonnummer ein (z.B. 013456789)',
        (v) => !!v && v.length != 0 || 'Eingabe ist erforderlich'
      ],
      tot: false
    }
  },
  watch: {
    selectedFormId(newVal, oldVal) {
      if (newVal != oldVal && newVal != null && newVal != '') {
        this.loading = true
        var myHeaders = new Headers();
        myHeaders.append('x-api-key', window.apiKey);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        fetch(`${window.baseApi}/form/${encodeURIComponent(newVal)}`, requestOptions)
            .then(response => {
              if (response.ok) {
                return response.json()
              } else {
                this.$emit('form-close')
                alert('Das angegefragt Formular wurde nicht gefunden!')
              }
            })
            .then(result => {
              if (result == null) {
                return
              }
              this.loading = false
              this.selectedForm = result
              this.formData = {}
              this.showForm()
            })
            .catch(error => console.log('error', error));
      }
    },
    dates: {
      handler() {
        for (var key in this.dates) {
          const [year, month, day] = (this.dates[key] + '').split('-')
          this.formData[key] = `${day}.${month}.${year}`
        }
      },
      deep: true
    }
  },
  computed: {},
  methods: {
    showForm() {
      this.formData = {}
      this.form = []
      this.dates = {}
      this.user.acknowledge = false

      for (var i in this.selectedForm.order) {
        var prop = this.selectedForm.order[i]
        this.createElement(this.selectedForm.properties[prop], prop)
      }

      if (screen.width > 430) {
        this.navOpen = true
      }
    },
    createElement(p, prop) {
      p.key = prop
      p.label = p.title
      p.required = (this.selectedForm.required || []).indexOf(prop) != -1
      if (p.enum != null) {
        p.type = 'select'
      }
      var index = this.form.findIndex(x => x.key == prop)
      if (index != -1) {
        this.form.splice(index, 1, p)
      } else {
        this.form.push(p)
      }
      if (p.type == 'select' && p.enum.findIndex(x => x.value == this.formData[prop]) == -1) {
        this.formData[prop] = ''
      }
    },
    updateForm: function () {
      var found = false

      for (const field of this.form) {
        if (this.selectedForm.dependencies[field.key]) {
          for (const dep of this.selectedForm.dependencies[field.key].oneOf) {
            if (dep.properties) {
              for (var d in this.formData) {
                if (dep.properties[d] && dep.properties[d].const == this.formData[d] /*&& dep.properties[d].enum && dep.properties[d].enum.indexOf(this.formData[d]) != -1*/) {
                  for (const prop in dep.properties) {
                    found = true
                    var value = dep.properties[prop]
                    if (value.type != null || value.enum) {
                      this.createElement(value, prop)
                    }
                    for (var s in this.form) {
                      if (dep.properties[this.form[s].key] == null && this.selectedForm.properties[field.key] == null) {
                        this.form.splice(s, 1)
                      }
                    }
                  }
                }
              }
            }
            field.required = (dep.required || []).indexOf(d) != -1 && (field.type == 'select' && field.enum.length != 0);
          }
        }

      }

      if (found) {
        var remove = [];
        for (var key in this.formData) {
          if (!this.form.indexOf(key) == -1) {
            remove.add(key);
          }
        }
        remove.forEach(x => delete this.formData[x])
      }
    },
    validateSend() {
      if (this.$refs.form.validate()) {

        this.loading = true
        var newFormData = {}
        var files = []
        for (var d in this.formData) {
          var title = d
          var type = ''
          this.form.forEach(x => {
            if (x.key == d) {
              title = x.label
              type = x.type
            }
          })
          if (type == 'file') {
            files.push(this.formData[d])
          } else {
            newFormData[title] = this.formData[d]
          }
        }
        this.user.phoneNumber = this.user.phoneNumber.replace('+', '00')

        var data = {
          form: newFormData,
          user: this.user,
          title: this.selectedForm.title,
          extFormType: this.selectedFormId
        }

        var myHeaders = new Headers();
        myHeaders.append('x-api-key', window.apiKey);
        const formData = new FormData();
        formData.append("json",JSON.stringify(data))
        files.forEach(file => {
          formData.append('files', file);
        });
        // myHeaders.append('Content-Type', 'application/json');
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: formData
        }

        fetch(`${window.baseApi}/form/submit`, requestOptions)
            .then(response => {
              this.loading = false
              if (response.ok) {
                this.$emit('form-send')
              } else {
                alert('Fehler bei der Übertragung')
              }
            })
            .catch(error => console.log('error', error));
      }
    }
  }
}

</script>

<style scoped lang="scss">
.forumlar-element {
  transition: margin-right 1s;
  margin: 20px 0;
  display: flex;
  width: 380px;
  margin-right: -360px;
  max-height: 560px;

  &.open {
    .nav-control {
      img {
        &.close {
          display: initial;
        }

        &.open {
          display: none;
        }
      }
    }

    margin-right: 0;
  }

  .nav-control {
    background: var(--dark-chat-color);
    width: 20px;
    height: 100%;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    display: flex;
    flex-shrink: 0;

    img {
      width: 100%;

      &.close {
        display: none;
      }
    }
  }

  .formular-container {
    background: var(--middle-chat-color);
    flex-grow: 1;
    flex-shrink: 1;
    width: 400px;

    .header {
      height: 60px;
      border-bottom: 1px solid var(--border-chat-color);
      color: var(--middle-chat-text-color, white);
      font-size: 18px;
      line-height: 1.4;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 30px;

      img {
        display: none;
      }
    }

    .inline-scroll {
      overflow: auto;
      max-height: calc(100% - 60px);
      padding: 0 30px;
      position: relative;

      form {
        position: relative;

        .progress-container {
          display: flex;
          justify-content: center;
          position: absolute;
          top: 0;
          left: -30px;
          bottom: 0;
          right: -30px;
          align-items: center;
          background: rgba(0, 0, 0, 0.27);
          z-index: 1;
        }
      }
    }

    .form-container {
      display: flex;
      flex-direction: column;

      .description {
        color: var(--middle-chat-text-color, white);

        p {
          color: var(--middle-chat-text-color, white);
        }

        font-size: 14px;
        margin-bottom: 15px;
        margin-top: 10px;
      }

      button {
        background: var(--light-chat-color);
        padding: 5px 15px;
        align-self: self-end;
        margin-bottom: 20px;
      }

      .legal {
        font-size: 13px;
        margin-bottom: 50px;
      }

      .subheader {
        margin: 10px 0;
        color: var(--middle-chat-text-color, white);
      }
    }
  }
}


@media screen and (max-width: 430px) {
  .formular-element {
    &.open {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      max-height: initial;
      margin: 0;
      margin-right: 0;
      width: auto;
    }

    &.close {
      display: none;
    }

    .formular-container {
      .header {
        img {
          position: absolute;
          right: 30px;
          display: initial;
          width: auto;
        }
      }
    }

    .nav-control {
      display: none;
    }
  }
}

</style>
<style>
.theme--light.v-text-field--filled:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot:hover,
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: #E5E5E5;
}

.formular-element input:focus:not(.button):not([type=submit]),
.formular-element select:focus:not(.button):not([type=submit]),
.formular-element textarea:focus:not(.button):not([type=submit]) {
  background: inherit;
  outline: none;
  border: none;
  box-shadow: none;
}

.forumlar-element {
  .form-container {
    display: flex;
    flex-direction: column;

    .v-input--checkbox .v-label,
    .v-input--is-readonly .v-label {
      color: var(--middle-chat-text-color, white);
    }

    .v-icon {
      color: var(--middle-chat-text-color, white);
    }

    .description {
      p {
        color: var(--middle-chat-text-color, white);
      }
    }
  }
}
</style>


