export class AgentChatApi {
    static getBaseUrl() {
        return `${window.baseApi}/chatRest`
    }

    static sendMsg(msg) {
        return this.postRequest(`/chats/${this.getAgentchat().chatId}/messages`, {message: msg});
    }

    static download(attId) {
        return fetch(`${this.getBaseUrl()}/chats/${this.getAgentchat().chatId}/attachments/${attId}`, {
            headers: this.getHeaders(),
            credentials: 'same-origin'
        }).then(resp => resp.blob()).catch(x => this.handleResponse(x))
    }

    static upload(fileList) {
        var data = new FormData()
        for (const file of fileList) {
            data.append('file', file, file.name)
            data.append('filename', file.name)
            data.append('size', file.size)
            data.append('contentType', file.type)
            data.append('visibility', 'V')
        }

        this._doFormPost(`${this.getBaseUrl()}/chats/${this.getAgentchat().chatId}/attachments`, data)
    }

    static startTyping() {
        return this.postRequest(`/chats/${this.getAgentchat().chatId}/typing/start`, {});
    }

    static stopAgentChat()  {
        return this.postRequest(`/chats/${this.getAgentchat().chatId}/stop`, {});
    }

    static stopTyping() {
        return this.postRequest(`/chats/${this.getAgentchat().chatId}/typing/stop`, {});
    }

    static sendRating(dd) {
        return this.postRequest(`/chats/${this.getAgentchat().chatId}/rating`, {ratingElements: [dd]});
    }

    static _doFormPost(url, data) {
        var header = this.getHeaders()
        // header['content-type'] = 'application/json'
        return fetch(url, {
            method: 'POST',
            headers: header,
            body: data
        }).then(resp => this.handleResponse(resp))
    }

    //
    // static sendEvent(eventName, params) {
    //     const formData = new FormData();
    //
    //     params.forEach(v => formData.append('param', v))
    //     return this.postFormRequest('/api/rest/events/' + eventName, formData).then(r => JSON.parse(r));
    // }

    static initAgentApi(category) {
        this.getRequest(`/status?category=${category}`).then(j => {
            var json = JSON.parse(j)
            this.handleChangesResponse(json)

            if (json.inBusinessTime == true && json.available == true) {
                var chatId = this.getAgentchat().chatId
                if (chatId != null) {
                    this.postRequest(`/chats/${chatId}/reinit`, {}).then(json => {
                        this.setupChangesCall();
                        if (json != '') {
                            this.handleChangesResponse(JSON.parse(json))
                        }
                    })
                }
            }
        })
    }

    static checkInit(username, category) {
        if (username != null) {
            return this.postRequest('/chats', {
                'category': category,
                'nickname': username,
                'language': 'DE',
                'channel': 'TEXT',
                // 'email': 'john.doe@example.org',
                'refid': 'adasdaasds',
                // 'url': 'www.my.chat.example.org',
                // 'cobrowseAvailable': true
            }).then(json => {
                sessionStorage.agentchat = json
                this.fetchChanges()
            })
        }
    }

    // static initAgentApi() {
    //     if (this.initTimer != null) {
    //         clearTimeout(this.initTimer)
    //         this.initTimer = null
    //     }
    //
    //     var chatId = this.getAgentchat().chatId
    //     if (chatId == null) {
    //         return
    //     }
    //
    //     this.initTimer = setInterval(() => this.fetchChanges(), 1000)
    // }

    static fetchChanges() {
        var chatId = this.getAgentchat().chatId
        this.getRequest(`/chats/${chatId}/changes`).then(json => {
            if (json != '') {
                this.handleChangesResponse(JSON.parse(json))
            } else {
                this.setupChangesCall()
            }
        })
    }

    static stopPolling() {
        clearInterval(this.initTimer);
        sessionStorage.removeItem('agentchat')
    }

    static handleChangesResponse(json) {
        if (json == null) {
            return
        }

        //status call
        if (json.available != null) {

            if (json.inBusinessTime == false) {
                document.dispatchEvent(new CustomEvent('chat-api-status', {detail: {type: 'chat-init', data: {'inBusinessTime': false}}}))
            } else if (json.inBusinessTime && json.available == false) {
                document.dispatchEvent(new CustomEvent('chat-api-status', {detail: {type: 'chat-init', data: {'inBusinessTime': true, 'available': false}}}))
            } else if (json.inBusinessTime && json.available == true) {
                document.dispatchEvent(new CustomEvent('chat-api-status', {detail: {type: 'chat-init', data: {'inBusinessTime': true, 'available': true}}}))
            } else {
                console.error('Hey Kategorie passt nicht')
                console.error(json)
                document.dispatchEvent(new CustomEvent('chat-api-status', {detail: {type: 'chat-init', data: {'inBusinessTime': true, 'available': true}}}))
            }
        }

        if (json.changes != null) {
            json.changes.forEach(x => this.handleChangesResponse(x))
        }
        switch (json.type) {
            // case 'ErrorResponse':break;
            // case 'ChatStartResponse':break;
            // case 'ChatChange':break;
            case 'ChatChangeChatstep': {
                if (json.chatstepType != 1) {
                    json.message = JSON.parse(json.message)
                }
                document.dispatchEvent(new CustomEvent('chat-api-status', {detail: {type: 'chatstep', data: json}}))
                break;
            }
            // case 'ChatChangeChatstepAck':break;
            case 'ChatChangeInitAck': {
                document.dispatchEvent(new CustomEvent('chat-api-status-start'))
                break;
            }
            case 'ChatChangeInitNack': {
                this.setupChangesCall();
                break
            }
            case 'ChatChangeInitChatId': {
                this.stopPolling()
                sessionStorage.agentchat = JSON.stringify(json)

                this.setupChangesCall();
                break;
            }
            case 'ChatChangeMetainformation': {
                var msg = JSON.parse(json.message)
                if (msg.waitingLine != null) {
                    document.dispatchEvent(new CustomEvent('chat-api-status', {detail: {type: 'wait', data: msg}}))
                }
                if (msg.typing != null) {
                    document.dispatchEvent(new CustomEvent('chat-api-status', {detail: {type: 'typing', data: msg}}))
                }
                break;
            }
            // case 'ChatChangeSendMailAllowed':break;
            case 'ChatChangeStop': {
                this.stopPolling()
                document.dispatchEvent(new CustomEvent('chat-api-status', {detail: {type: 'stop', data: json}}))
                break;
            }
            case 'ChatChangeStopPolling': {
                this.stopPolling()
                console.info('Stop polling ' + json.code)
                document.dispatchEvent(new CustomEvent('chat-api-status', {detail: {type: 'stop', data: {}}}))
                break;
            }
            // case 'ChatChangesResponse':break;
            // case 'WebRtcChange':break;
            // case 'RatingElement':break;
            // case 'FEOptions':break;
            // case 'FrontendDataResponse':break;
            // case 'FrontendRulesResponse':break;
            // case 'StatusResponse':break;
            default: {

                if (json.code != null) { //error
                    switch (json.code) {
                        case 410:
                            console.warn('Error 410')
                            this.stopPolling()
                            document.dispatchEvent(new CustomEvent('chat-api-status', {detail: {type: 'stop', data: {}}}))
                            // document.dispatchEvent(new CustomEvent('chat-api-status', {detail: {type: 'error', data: json.code}}))
                            break;
                    }
                }
                console.log(json)
            }
        }
    }


    static setupChangesCall() {
        if (this.initTimer) {
            clearInterval(this.initTimer);
        }
        this.initTimer = setInterval(() => this.fetchChanges(), 1000)
        // if(this.websocket != null){
        //     this.websocket.close()
        // }
        // var chatId = this.getAgentchat().chatId
        // this.websocket = new WebSocket(`wss://pp136.novomind.com/chatRest/chats/${chatId}/changes`/*, protocols*/);
        // // this.websocket.onopen = function (event) {
        // // };
        // this.websocket.onmessage = function (event) {
        //     this.handleChangesResponse(JSON.parse(event.data))
        // }.bind(this)
    }

    static getHeaders() {
        var myHeaders = new Headers();
        myHeaders.append('X-novomind-iAGENT-chat-token', this.getAgentchat().token || 'initialize');
        return myHeaders
    }

    static getAgentchat() {
        return JSON.parse(sessionStorage.agentchat || '{}') || {};
    }

    static getRequest(path) {
        return this._doGet(`${this.getBaseUrl()}${path}`)
    }

    static postRequest(path, params = {}) {
        return this._doPost(`${this.getBaseUrl()}${path}`, params)
    }

    static _doGet(url) {
        return fetch(url, {
            headers: this.getHeaders(),
            credentials: 'same-origin'
        }).then(resp => this.handleResponse(resp)).catch(x => this.handleResponse(x))
    }

    static _doPost(url, data) {
        var header = this.getHeaders()
        header.append('Content-Type', 'application/json');
        return fetch(url, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        }).then(resp => this.handleResponse(resp)).catch(x => this.handleResponse(x))
    }

    static handleResponse(resp) {
        if (resp.ok) {
            return resp.text()
        } else if (resp.status == 403) {
            return null;
        } else //if (resp.status == 410)
        {
            this.handleChangesResponse({code: 410})
            return null
        }
        // alert('Mist ging wohl schief')
        // return null
    }
}
