<template>
  <div>
    <div class="header">
      <img class="close-button" :src="`${imagePrefix}img/close.svg`" @click="$emit('close')">
      <!--      <div class="avatar-head">-->
      <!--        <img :src="avatar">-->
      <!--      </div>-->
      <button class="stupid_agent_chat_close" @click="stopAgentChat" v-if="agentChat">Beenden</button>
    </div>
    <div class="chat-list">
      <div class="chat-list-body">
        <chat-entry v-for="(entry, i) in chatHistory" :request="entry" :time="entry.time" :agent="entry.agent" :key="i"
                    @say="sendEventMsg($event)" @form="$emit('form',$event)"></chat-entry>
      </div>
      <div class="typing" v-if="agentTyping">typing<span>.</span><span>.</span><span>.</span><span>.</span></div>

      <rating-element v-if="showRating" @rating="chatApi.sendRating($event)"></rating-element>

      <div class="suggestions" v-if="showSuggestions && suggestions && suggestions.length != 0">
        <div class="suggestions-arrow" @click="suggScrollLeft"><img :src="`${imagePrefix}img/left.svg`"></div>
        <div class="suggestions-body">
          <div class="suggestions-slider">
            <div v-for="sug in suggestions" :key="sug.id">
              <div @click="askFor(sug)">
                {{ sug.question }}
              </div>
            </div>
          </div>
        </div>
        <div class="suggestions-arrow" @click="suggScrollRight"><img :src="`${imagePrefix}img/right.svg`"></div>
      </div>
      <div class="fake-message" v-if="showAgentWait">
        <div class="init-agent">
          <div v-if="waitTime == null && waitPosition == null">
            Bitte warten, Verbindung wird aufgebaut
          </div>
          <div v-else>
            <div v-if="waitPosition != null">Warteschlange: {{ waitPosition }}</div>
            <div v-if="waitTime != null">Wartezeit: Ca {{ waitTime }} Minuten</div>
          </div>
          <div class="d-flex buttons">
            <div @click="stopAgentChat">Chat beenden</div>
          </div>
        </div>
      </div>
      <div class="chat-list-footer" v-if="showInitAgent && !showAgentWait">
        <div class="init-agent">
          <div v-if="!showAgentWait">
            <div class="input-container">
              <input type="text" v-model="username" placeholder="Wie sollen wir sie ansprechen" autocomplete="off" spellcheck="false"
                     class="message-input" @keyup.enter="startAgentChat" cy-data="username-input">
            </div>
            <button @click="startAgentChat" class="send-button" aria-label="Absenden">
              <svg viewBox="0 0 24 24">
                <path d="M9.6 17.4L5.3 13l4.3-4.4.8.8L6.7 13l3.7 3.6z"></path>
                <path d="M19.5 13.5H6v-1h12.5v-4H16v-1h3.5z"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="chat-list-footer" v-else>
        <div class="input-container">
          <input v-model="chatMsg" placeholder="Antwort" aria-label="Ihre Nachricht" type="text" @keyup="msgTyping" :disabled="showAgentWait"
                 class="message-input" @keyup.enter="sendMsg" cy-data="message-input">
        </div>
        <button class="send-button" aria-label="Nachricht senden" @click="sendMsg" :disabled="showAgentWait">
          <img :src="`${imagePrefix}img/send.png`">
        </button>
        <div class="attachment-button" v-if="allowAttachments && agentChat" role="button" aria-label="upload">
          <img :src="`${imagePrefix}img/attachment.png`">
          <input type="file" @change="uploadAttachment">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ChatApi} from '@/api/ChatApi';
import {AgentChatApi} from '@/api/AgentChatApi';
import ChatEntry from '@/components/ChatEntry';
import RatingElement from './RatingElement';

export default {
  name: 'ChatList',
  props: ['agentChat', 'showClose', 'allowAttachments', 'showSuggestions', 'hideFaq', 'showRating', 'initEvent', 'avatarPath', 'chatCategory', 'imagePrefix'],
  components: {RatingElement, ChatEntry},
  data() {
    return {
      chatMsg: '',
      chatHistory: [],
      isAgentChatInit: false,
      agentTyping: false,
      username: '',
      waitPosition: null,
      waitTime: null,
      showAgentWait: false,
      suggestions: [],
      agentName: '',
      agentImage: null,
      suggScroll: 0,
    }
  },
  computed: {
    chatApi: {
      get() {
        return this.agentChat == true ? AgentChatApi : ChatApi
      }
    },
    showInitAgent: {
      get() {
        return !this.isAgentChatInit && this.agentChat
      }
    },
    avatar: {
      get() {
        return this.isAgentChatInit ? this.agentImage : this.avatarPath;
      }
    },
    // prefix: {
    //   get() {
    //     return process.env.VUE_APP_IMG_PREFIX || ''
    //   }
    // }
  },
  watch: {
    agentChat(newVal) {
      if (newVal) {
        console.log('Check for init Agent')
        this.initAgentChat()
        this.username = ''
      }
    }
  },
  methods: {
    stopAgentChat() {
      this.chatApi.stopAgentChat();
      this.isAgentChatInit = false
      this.showAgentWait = false
      this.$emit('chatevent', {type: 'disableAgent'});
    },
    suggScrollRight() {
      if (this.suggScroll != this.suggestions.length - 1) {
        var el = this.$el.querySelectorAll('.suggestions-slider > div')[this.suggScroll++ % this.suggestions.length]
        el.parentElement.parentElement.scrollLeft += el.offsetWidth + 10
      }
    },
    suggScrollLeft() {
      if (this.suggScroll > 0) {
        var el = this.$el.querySelectorAll('.suggestions-slider > div')[--this.suggScroll % this.suggestions.length]
        el.parentElement.parentElement.scrollLeft -= el.offsetWidth + 10
      }
    },
    restartChat() {
      this.isAgentChatInit = false
    },
    msgTyping(event) {
      if (event.keyCode == 13) {
        if (this.agentChat) {
          this.chatApi.stopTyping()
        }
        return
      }
      if (this.agentChat) {
        this.chatApi.startTyping()
        this.refreshTypingTimer()
      } else if (this.chatMsg.trim() != '' && this.showSuggestions) {
        ChatApi.suggest(this.chatMsg.trim()).then(d => {
          this.suggestions = JSON.parse(d)
          this.suggScroll = 0;
        })
      }
    },
    refreshTypingTimer() {
      if (this.typingTimer) {
        clearInterval(this.typingTimer)
      }
      this.typingTimer = setTimeout(() => {
        this.chatApi.stopTyping()
        console.log('eeende')
      }, 10000)
    },
    uploadAttachment(event) {
      this.chatApi.upload(event.target.files)
    },
    startAgentChat() {
      if (this.username.trim().length != 0) {
        this.showAgentWait = true;
        this.suggestions = [];
        this.chatApi.checkInit(this.username, this.chatCategory)
      }
    },
    sendEventMsg(msg) {
      this._sendMsg(msg)
    },
    sendMsg() {
      this.suggestions = []
      var msg = this.chatMsg.trim()
      if (msg == 'RateMe') {
        //this.showRating = true;
        return;
      }
      this._sendMsg(msg)
    },
    _sendMsg(msg) {
      if (msg.length != 0) {
        this.loading = true
        this.pushMessageItem({msg, time: new Date()})
        setTimeout(() => {
          document.querySelector('.chat-list-body > div:last-child').scrollIntoView()
        }, 100)
        this.chatMsg = ''
        this.chatApi.sendMsg(msg).then(resp => {
          this.loading = false
          this.handleResponse(resp)
          if ((resp.text || '') != '') {
            this.pushMessageItem({msg: resp.text, time: new Date(), agent: false, agentPath: this.avatar.toString(), form: resp.form})
            setTimeout(() => {
              document.querySelector('.chat-list-body > div:last-child').scrollIntoView()
            }, 100)
          }
        })
      }
    },
    handleResponse(resp) {
      if (resp.js) {
        (resp.js || '').split(';').forEach(e => {
          if (e.toLowerCase().startsWith('video:')) {
            this.pushMessageItem({
              time: new Date(),
              agent: false,
              agentPath: this.avatar.toString(),
              type: 'video',
              mediaPath: e.substring('video:'.length)
            })
          } else if (e.toLowerCase().startsWith('audio:')) {
            this.pushMessageItem({
              time: new Date(),
              agent: false,
              agentPath: this.avatar.toString(),
              type: 'audio',
              mediaPath: e.substring('audio:'.length)
            })
          } else if (e.toLowerCase().startsWith('image:')) {
            this.pushMessageItem({
              time: new Date(),
              agent: false,
              agentPath: this.avatar.toString(),
              type: 'image',
              mediaPath: e.substring('image:'.length)
            })
          }
        })
        this.$emit('jsevent', resp)
      }
    },
    handleAgentResponse(resp) {
      console.log(resp)
      switch (resp.detail.type) {
        case 'chatstep': {
          switch (resp.detail.data.chatstepType) {
            case 0: {
              console.info('Agent entered')
              this.showAgentWait = false
              this.isAgentChatInit = true
              if (resp.detail.data.chatstepSemantic == 'AgentJoined') {
                this.agentName = resp.detail.data.nickname
                this.chatApi.download(resp.detail.data.origin).then(x => {
                  const reader = new FileReader();
                  reader.readAsDataURL(x);
                  return new Promise(resolve => {
                    reader.onloadend = () => {
                      resolve(reader.result);
                    };
                  });
                }).then(x => {
                  this.agentImage = x
                  var d = new Date()
                  d.setTime(resp.detail.data.timestamp)
                  this.pushMessageItem({agent: true, time: d, msg: this.agentName + ' ist beigetreten', agentPath: this.avatar.toString()})
                  setTimeout(() => {
                    document.querySelector('.chat-list-body > div:last-child').scrollIntoView()
                  }, 100)
                })
                break;
              }
              break
            }
            case 1: {
              var d = new Date()
              d.setTime(resp.detail.data.timestamp)
              this.pushMessageItem({msg: resp.detail.data.message, time: d, agent: true, agentPath: this.avatar.toString()})
              setTimeout(() => {
                document.querySelector('.chat-list-body > div:last-child').scrollIntoView()
              }, 100)
              break
            }
            case 14:
            case 13: {
              this.chatApi.stopPolling()
              alert('Es trat ein Technischer Fehler auf, die Unterhaltung wird beendet. (' + resp.detail.data.chatstepType + ')')
              break
            }
            case 45: {
              this.chatApi.download(resp.detail.data.message.id).then(x => {
                var d = new Date()
                d.setTime(resp.detail.data.timestamp)
                this.pushMessageItem({
                  blob: x,
                  time: d,
                  agent: resp.detail.data.origin.startsWith('customer') ? null : true,
                  type: 'link',
                  name: resp.detail.data.message.name,
                  agentPath: this.avatar.toString()
                })
                setTimeout(() => {
                  document.querySelector('.chat-list-body > div:last-child').scrollIntoView()
                }, 100)
              })
              break;
            }
          }
          break
        }
        case 'wait': {
          this.waitPosition = resp.detail.data.waitingLine.position
          this.waitTime = resp.detail.data.waitingLine.time
          break
        }
        case 'typing': {
          console.log('type')
          this.agentTyping = resp.detail.data.typing
          break
        }
        case 'stop': {
          this.isAgentChatInit = false
          this.showAgentWait = false
          this.$emit('chatevent', {type: 'disableAgent'});
          break
        }
        case 'error': {
          this.chatApi.stopPolling()
          alert('Es trat ein Technischer Fehler auf, die Unterhaltung wird beendet. (' + resp.detail.data + ')')
          break
        }
        case 'chat-init': {
          if (resp.detail.data.inBusinessTime && !resp.detail.data.available) {
            alert('Leider ist aktuell kein Agent verfügbar, bitte versuchen Sie später noch einmal')
            this.$emit('chatevent', {type: 'disableAgent'});
            break;
          } else if (!resp.detail.data.inBusinessTime) {
            alert('Leider sind aktuell keine Betriebszeiten, bitte kommen sie zu unseren Betriebszeiten wieder')
            this.$emit('chatevent', {type: 'disableAgent'});
            break;
          } else {
            this.startAgentChat()
          }
        }
      }
    },
    initAgentChat() {
      this.chatApi.initAgentApi(this.chatCategory)
    },
    askFor(faq) {
      this.chatMsg = ''
      this.suggestions = []
      this.pushMessageItem({msg: faq.question, time: new Date()})
      ChatApi.getFAQ(faq.id).then(d => {
        var json = JSON.parse(d)
        this.pushMessageItem({msg: {response: json.answers[0]}, time: new Date(), agent: false, agentPath: this.avatar.toString()})
        setTimeout(() => {
          document.querySelector('.chat-list-body > div:last-child').scrollIntoView()
        }, 100)
      })
    },
    sendEvent(name, data) {
      return ChatApi.sendEvent(name, data)
    },
    initBotChat() {
      this.chatApi.checkInit().then(res => {
        if (res && res.length != 0) {
          if (sessionStorage.getItem('chatHistory') != '[]') {
            var l = JSON.parse(sessionStorage.getItem('chatHistory'))
            l.forEach(x => x.time = new Date(x.time))
            this.chatHistory = l

            if (l.length > 0 && l[l.length - 1].form) {
              var item = l[l.length - 1]
              item.js = 'form:' + item.form
              this.$emit('jsevent', item)
            }
          } else {
            for (let i = res.length - 1; i >= 0; i--) {
              const req1 = res[i];
              this.pushMessageItem({msg: req1.text, time: new Date(req1.timestamp), agent: req1.sender == 0 ? true : null, agentPath: this.avatar.toString()});
            }
          }
          setTimeout(() => {
            document.querySelector('.chat-list-body > div:last-child').scrollIntoView()
          }, 100)
        } else {
          //remove mabe exsting history
          sessionStorage['chatHistory'] = '[]'
        }
        if (this.chatHistory.length == 0 && this.initEvent) {
          var ev = this.initEvent
          if (ev.name == 'NEWUSER2') {
            ev.name = 'STARTFREE'
            if (ev.params.length > 2) {
              ev.params[1] = ev.params[2]
            }
          }

          this.sendEvent(ev.name, ev.params).then(resp => {
            var urlParams = new URLSearchParams(window.location.search);
            if (urlParams.get('chatbot') == null) {
              this.pushEventResponse(resp);
            }

            setTimeout(() => {
              if (document.querySelector('.chat-list-body > div:last-child')) {
                document.querySelector('.chat-list-body > div:last-child').scrollIntoView()
              }
            }, 100)
            this.handleAdditionalInitEvent()
          })
        } else {
          this.handleAdditionalInitEvent()
        }
      })
    },
    handleAdditionalInitEvent() {
      var urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get('chatbot') != null) {
        var encoded = atob(urlParams.get('chatbot'))
        urlParams = new URLSearchParams(encoded);
        this.sendEvent(urlParams.get('event'), urlParams.getAll('param')).then(resp => {
          this.pushEventResponse(resp);
        })
      }
    },
    pushEventResponse: function (resp) {
      if ((resp.text || '') == '' || this.chatHistory.length != 0 && this.chatHistory[this.chatHistory.length - 1].msg == resp.text) {
        return
      }
      this.pushMessageItem({msg: resp.text, time: new Date(), agent: false, agentPath: this.avatar.toString()})
      setTimeout(() => {
        document.querySelector('.chat-list-body > div:last-child').scrollIntoView()
      }, 100)
    },
    pushMessageItem: function (items) {
      var l = JSON.parse(sessionStorage['chatHistory'])
      l.push(items)
      sessionStorage['chatHistory'] = JSON.stringify(l)
      this.chatHistory.push(items)
    },
  },
  mounted() {
    document.addEventListener('chat-api-status', this.handleAgentResponse)
    document.addEventListener('chat-api-status-start', () => this.isAgentChatInit = true)
    if ((sessionStorage.getItem('chatHistory') || '').length < 3) {
      sessionStorage['chatHistory'] = '[]'
    }
    if (this.agentChat) {
      this.initAgentChat()
    } else {
      this.initBotChat()
    }
  }
}
</script>

<style scoped lang="scss">

.header {
  height: 60px;
  background: var(--dark-chat-color);
  z-index: 1;
  position: relative;

  .avatar-head {
    position: relative;
    left: calc(50% - 40px);
    width: 80px;
    height: 80px;
    bottom: -23px;
    border-radius: 35px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .stupid_agent_chat_close {
    position: absolute;
    right: 10px;
    top: 18px;
    color: var(--light-chat-color);
  }
}

.close-button {
  position: absolute;
  padding: 5px 8px;
  z-index: 10;
  color: white;
  cursor: pointer;
  left: 7px;
  top: 12px;
}

.chat-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  background: var(--background-chat-color);
  border-top: 1px solid var(--border-chat-color);
  overflow: auto;

  .typing {
    font-size: 13px;
    color: #bbbbbb;
    text-align: left;

    span {
      animation-iteration-count: infinite;
      animation-name: typing-ani;
      animation-duration: 2s;
      transition: opacity .5s;
    }

    span:nth-child(2) {
      animation-delay: .5s;
    }

    span:nth-child(3) {
      animation-delay: 1s;
    }

    span:nth-child(4) {
      animation-delay: 1.5s;
    }
  }

  .fake-message {
    > div {
      margin: 10px;
      padding: 20px;
      border-radius: 20px;
      background-color: var(--dark-chat-color);
      color: white;
    }

    .buttons {
      justify-content: right;

      > * {
        color: var(--light-chat-color);
        cursor: pointer;
      }
    }
  }

  @keyframes typing-ani {
    from {
      opacity: 0
    }
    to {
      opacity: 1
    }
  }

  .chat-list-body {
    flex-grow: 1;
    overflow: auto;
    padding: 15px;

    > *:first-child {
      margin-top: 30px;
    }
  }

  .chat-list-footer {
    display: flex;
    padding: 15px;
    background: var(--light-chat-color);

    align-items: center;
    gap: 10px;

    .init-agent {
      width: 100%;

      .send-button {
        padding: 0;
        width: 40px;
      }

      > div {
        display: flex;
      }

      div {
        width: 100%;
      }
    }

    .input-container {
      padding: 0 1em 0 1em;
      flex-grow: 1;
      font-size: 1em;
      line-height: 1.5;
      border-radius: 20px;
      border-color: hsl(200, 63.2%, 78.7%);
      border-style: solid;
      border-width: 1px 0 1px 1px;
      clear: both;
      height: 38px;
      margin: 0 auto;
      display: flex;
      position: relative;
      max-width: 700px;
      background: #ffffff;
      align-items: center;

      border-left: none;

      input {
        width: 100%;
        border: 0;
        height: 100%;
        outline: 0;
        font-size: 1em;
        background: transparent;
        font-family: inherit;
        -webkit-appearance: none;

        &:focus {
          box-shadow: none !important;
        }
      }
    }

    .send-button {
      display: flex;
      border: none;
      //height: 100%;
      position: relative;
      font-family: inherit;
      background: transparent;

      img {
        height: 30px;
      }
    }

    input[disabled],
    button[disabled] {
      opacity: .3;
    }
  }

  .suggestions {
    overflow: auto;
    //display: flex;
    padding: 10px 0;
    background: var(--light-chat-color);
    border-top: 1px solid var(--border-chat-color);
    border-bottom: 1px solid var(--border-chat-color);
    position: relative;
    flex-shrink: 0;

    > * {
      cursor: pointer;
      flex-grow: 0;
    }

    .suggestions-arrow {
      padding-left: 5px;
      padding-right: 5px;
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      bottom: 0;

      &:first-child {
        left: 0;
        background: linear-gradient(90deg, var(--light-chat-color) 50%, transparent);
      }

      &:last-child {
        right: 0;
        background: linear-gradient(-90deg, var(--light-chat-color) 50%, transparent);
      }
    }

    .suggestions-body {
      flex-grow: 1;
      overflow: hidden;

      .suggestions-slider {
        width: 9800px;
        display: flex;

        > * {
          background: var(--background-chat-color);
          padding: 10px;
          margin: 0 5px;
          border-radius: 5px;

          &:first-child {
            margin-left: 30px
          }

          &:last-child {
            margin-right: 30px;
          }
        }
      }
    }
  }
}

.attachment-button {
  width: 44px;
  height: 40px;
  background-color: black;
  position: relative;
  border-left: solid 1px white;

  input[type=file] {
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: rotate(-90deg);
  }
}

@media screen and (min-width: 430px) {
  .close-button {
    display: none;
  }
}

@media screen and (max-height: 700px) {
  .close-button {
    display: inherit;
  }

}

</style>
