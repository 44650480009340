export class ApiBase {
    static getBaseUrl() {
        return window.baseApi//`${process.env.VUE_APP_API_HOST}`
    }

    static getHeaders() {
        return {
            "Accept": "application/vnd.novomind.iq-v3.0+json",
            "x-auth-key": sessionStorage.token,
            "x-api-key": window.apiKey
        }
    }

    static getRequest(path, options) {
        return this._doGet(`${this.getBaseUrl()}${path}`, options)
    }

    static deleteRequest(path) {
        return this._doDelete(`${this.getBaseUrl()}${path}`)
    }

    static postRequest(path, params = {}) {
        return this._doPost(`${this.getBaseUrl()}${path}`, params)
    }

    static postFormRequest(path, params = {}) {
        return this._doFormPost(`${this.getBaseUrl()}${path}`, params)
    }

    static putRequest(path, params = {}) {
        return this._doPut(`${this.getBaseUrl()}${path}`, params)
    }

    static _doGet(url, options) {
        return fetch(url, {
            headers: this.getHeaders(),
            // credentials: 'same-origin'
        }).then(resp => this.handleResponse(resp, options))
    }

    static _doDelete(url) {
        return fetch(url, {
            method: 'DELETE',
            headers: this.getHeaders()
        }).then(resp => this.handleResponse(resp))
    }

    static _doPost(url, data) {
        var header = this.getHeaders()
        header['content-type'] = 'application/json'
        return fetch(url, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        }).then(resp => this.handleResponse(resp))
    }

    static _doFormPost(url, data) {
        var header = this.getHeaders()
        // header['content-type'] = 'application/json'
        return fetch(url, {
            method: 'POST',
            headers: header,
            body: data
        }).then(resp => this.handleResponse(resp))
    }

    static _doPut(url, data) {
        var header = this.getHeaders()
        header['content-type'] = 'application/json'
        return fetch(url, {
            method: 'PUT',
            headers: header,
            body: JSON.stringify(data)
        }).then(resp => this.handleResponse(resp))
    }

    static handleResponse(resp, options) {
        if (resp.headers.get('x-auth-token') != undefined && resp.headers.get('x-auth-token') != 'undefined') {
            sessionStorage.token = resp.headers.get('x-auth-token')
        }
        if (resp.ok) {
            return resp.text()
        } else {
            if (resp.status == 403) {
                localStorage.removeItem('user')
                return null;
            }
        }
        if (options != null && options.ignoreError) {
            return null;
        }
        alert('Mist ging wohl schief')
        return null
    }
}
