<template>
  <div id="spiribochatelement" :class="`chat-element ${showChat ? '' : 'closed'}`">
    <link rel="stylesheet" href="https://cdn.plyr.io/3.7.2/plyr.css"/>
    <div :class="`flyout ${showChat ? '' : 'hidden'}`" v-if="chatRendered">
      <formular-element ref="form" :class="`formular-element ${showForm ? '' : 'hidden'}`" :selected-form-id="shouldSelectForm" @form-send="formSend"
                        :image-prefix="imagePrefix" :datenschutz-url="datenschutzUrl" @form-close="hideForm"></formular-element>
      <chat-list ref="chatList" class="chat-list-container" :avatar-path="`${imagePrefix}img/bot.png`" @jsevent="jsEventFired" :agent-chat="agentChatProp" :show-close="true"
                 :chat-category="chatCategory" :image-prefix="imagePrefix"
                 @close="showChat = false" :show-suggestions="showSuggestions" :init-event="initEvent" @chatevent="chatEvent" @form="formSelected"></chat-list>
    </div>
    <div role="button" tabindex="0" :class="`chat-container ${intShowText? 'show-text':''}`">
      <div class="head-container">
        <div class="head-button" @click="toggleChat">
          <img :src="`${imagePrefix}img/chat.svg`" v-if="!showChat">
          <span class="hide-chat" v-else>
            <img :src="`${imagePrefix}img/chevron-down.svg`">
          </span>
        </div>
        <div class="text-bubble" v-if="intShowText && !showChat" @click="toggleChat" v-html="showText">
        </div>
        <div class="close-text" @click="closeText" v-if="intShowText && !showChat">
          <img :src="`${imagePrefix}img/chevron-right.svg`">
        </div>
      </div>
      <div class="msg-number" v-if="msgNumber != null && !showChat">
        {{ msgNumber }}
      </div>
    </div>

  </div>
</template>

<script>
import {ChatApi} from '@/api/ChatApi';
import ChatList from './ChatList';
import FormularElement from '@/components/FormularElement';

export default {
  name: 'ChatElement',
  components: {FormularElement, ChatList},
  props: {
    agentChat: {type: Boolean, default: false},
    showSuggestions: {type: Boolean, default: false},
    initEvent: {type: Object},
    showText: {type: String},
    chatCategory: {type: String, default: 'Chat_SR'},
    msgNumber: {type: Number},
    imagePrefix: {type: String, default: 'https://freebot.spiri.bo/'},
    datenschutzUrl: {type: String},
  },
  data() {
    return {
      showChat: false,
      intShowText: false,
      shouldSelectForm: '',
      chatRendered: false,
      agentChatProp: false,
    }
  },
  watch: {
    showChat(newVal) {
      sessionStorage.chatOpen = newVal
    },
    agentChat(newVal) {
      this.agentChatProp = newVal;
    }
  },
  computed: {
    showForm: {
      get() {
        return this.shouldSelectForm != null && this.shouldSelectForm != ''
      }
    }
  },
  methods: {
    chatEvent(event) {
      switch (event.type) {
        case 'disableAgent':
          this.agentChatProp = false
          break
      }
      this.$emit('chatevent', event)
    },
    hideForm() {
      this.shouldSelectForm = ''
    },
    formSelected(event) {
      if (this.shouldSelectForm == event) {
        this.$refs.form.showForm()
      } else {
        this.shouldSelectForm = event
      }
      this.$refs.form.navOpen = true
    },
    formSend() {
      this.shouldSelectForm = ''
      this.$refs.chatList.chatHistory.push({msg: 'Erfolgreich versendet', time: new Date(), agent: false})
      setTimeout(() => {
        document.querySelector('.chat-list-body > div:last-child').scrollIntoView()
      }, 100)
    },
    toggleChat() {
      this.showChat = !this.showChat
      this.closeText()
      this.chatRendered = true
    },
    closeText() {
      this.intShowText = false
    },
    jsEventFired(event) {
      (event.js || '').split(';').forEach(e => {
        if (e.startsWith('form:')) {
          event.form = e.substring('form:'.length)
          if (event.form == this.shouldSelectForm) {
            if (screen.width > 430) {
              this.$refs.form.navOpen = true
            }
          } else {
            this.shouldSelectForm = event.form
          }
        }
      })
      this.$emit('jsevent', event.js)
    }
  },
  mounted() {
    this.intShowText = (this.showText || '') != ''
    const urlParams = new URLSearchParams(window.location.search);
    if (sessionStorage.chatOpen == 'true' || urlParams.get('chatbot') != null) {
      this.intShowText = false
      this.toggleChat();
    }
    ChatApi.validate()
  }
}
</script>

<style scoped lang="scss">

.hidden {
  display: none !important;
}

.chat-element {
  position: fixed;
  bottom: 0;
  right: 10px;
  display: flex;
  flex-direction: column;
  z-index: 2000;
  color: black;

  .flyout {
    display: flex;
    margin-bottom: 25px;
  }

  .chat-container {
    height: 70px;
    overflow: visible;
    cursor: pointer;
    //-webkit-filter: drop-shadow(0px 0px 6px #bbb);
    //filter: drop-shadow(0px 0px 6px #bbb);
    z-index: 2;
    align-self: flex-end;

    &:not(.show-text) {
      //width: 70px;
    }

    .msg-number {
      background: var(--signal-chat-color);
      padding: 2px 7px;
      border-radius: 20px;
      overflow: hidden;
      position: absolute;
      top: -5px;
      right: -5px;
      z-index: 1;
      border: 2px solid white;
      color: white;
      font-size: 14px;
      line-height: 1.3;
    }

    .head-container {
      overflow: hidden;
      box-shadow: 2px 2px 8px 0px rgb(0 0 0 / 55%);
      border-radius: 35px;
      top: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      display: flex;
      align-items: center;
      height: 56px;
      background: var(--light-chat-color);


      .head-button {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        border: 1px solid var(--light-chat-color);
        z-index: 1;
        -webkit-transition: -webkit-filter 0.2s;
        transition: -webkit-filter 0.2s;
        transition: filter 0.2s, -webkit-filter 0.2s;
        background: var(--light-chat-color);
        flex-shrink: 0;

        img {
          height: 60%;
          width: 60%;
          overflow: hidden;
          margin: 20%;
        }

        .hide-chat {
          width: 100%;
          height: 100%;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 40px;
            height: 40px;
            max-width: 40px;
          }
        }
      }

      .text-bubble {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        white-space: nowrap;
        font-size: 15px;
        line-height: 1.4em;
        padding: 4px 8px 4px 8px;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        z-index: 0;
        flex-shrink: 1;
      }

      .close-text {
        font-size: 30px;
        padding: 0 10px;
        flex-shrink: 0;

        img {
          max-width: 200px;
        }
      }
    }

  }

  &.closed {
    .chat-container {
      -webkit-transition: all 0.4s, opacity 0.4s 0.4s, visibility 0s;
      transition: all 0.4s, opacity 0.4s 0.4s, visibility 0s;
      visibility: visible;
      opacity: 1;
      display: block;
    }
  }

  .chat-list-container {
    max-width: 100%;
    width: 400px;
    max-height: 100vh;
    height: 600px;
    box-shadow: 0 10px 13px 0 rgb(0 0 0 / 30%);
    border: 1px solid var(--dark-chat-color);
    border-radius: 20px;
    overflow: hidden;
    z-index: 1;


    color: rgb(34, 34, 34);
    display: flex;
    font-size: 1em;
    box-sizing: border-box;
    word-break: break-word;
    white-space: normal;
    flex-direction: column;
    -webkit-tap-highlight-color: rgb(34, 34, 34);
  }

}

.big-chat {
  .chat-container {
    //width: 90px;
    height: 90px;

    .head-container {
      height: 90px;
      border-radius: 90px;

      .head-button {
        width: 90px;
        height: 90px;
      }
    }
  }
}

@media screen and (max-height: 700px) {
  .big-chat {
    .chat-container {
      //width: 90px;
      //height: 56px;
      display: none;

      .head-container {
        height: 56px;
        border-radius: 56px;

        .head-button {
          width: 56px;
          height: 56px;
        }
      }
    }
  }
  .chat-element {
    &:not(.closed) {
      top: 0;
      bottom: 0;
      right: 0;
      //left: 0;
      width: auto;
      height: auto;
      border-radius: 0;
    }
  }
}

@media screen and (max-width: 430px) {
  .chat-element {
    &:not(.closed) {
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: auto;
      height: auto;
      border-radius: 0;
    }

    .flyout {
      margin-bottom: 0;
      height: 100%;

      .chat-list-container {
        height: auto;
        border-radius: 0;
        width: 100%;
      }
    }

    .chat-container {
      display: none;
    }
  }
}
</style>
<style>
.v-application > .v-menu__content {
  z-index: 2001 !important;
}

#spiribochatelement input[type="text"],
#spiribochatelement input[type="file"],
#spiribochatelement input[type="email"],
#spiribochatelement input[type="number"],
#spiribochatelement input[type="select"],
#spiribochatelement textarea {
  border: none;
  padding: 0;
  outline: none;
  margin: 0;
  width: 100%;
  display: block;
  background: transparent;
  border-radius: 0;
}

#spiribochatelement .v-text-field--filled:not(.v-text-field--single-line) input {
  margin-top: 22px;
}

#spiribochatelement .v-textarea.v-text-field--box .v-text-field__prefix,
#spiribochatelement .v-textarea.v-text-field--box textarea,
#spiribochatelement .v-textarea.v-text-field--enclosed .v-text-field__prefix,
#spiribochatelement .v-textarea.v-text-field--enclosed textarea {
  margin-top: 24px;
}

#spiribochatelement .v-select.v-text-field:not(.v-text-field--single-line) input {
  margin-top: 0;
}

#spiribochatelement .v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
  padding-right: 12px;
}
</style>
